import { blogAction } from "../actionType"

const initialState = {
    catListData: {},
    tagsData: {},
    blogData: {},
    loader: false,
    loginStatus: false
}

export default function blogs(state = initialState, {type, payload}) {
  switch(type) {
    
    case blogAction.CATEGORY_LIST_INITIATE: {
      return {
        ...state, loader: true, catListData: {}
      }
    }
    case blogAction.CATEGORY_LIST_SUCCESS: {
      return {
        ...state, loader: false, catListData: payload.data
      }
    }
    case blogAction.CATEGORY_LIST_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    case blogAction.POPULAR_TAGS_INITIATE: {
      return {
        ...state, loader: true, tagsData: {}
      }
    }
    case blogAction.POPULAR_TAGS_SUCCESS: {
      return {
        ...state, loader: false, tagsData: payload.data
      }
    }
    case blogAction.POPULAR_TAGS_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    case blogAction.BLOG_LIST_INITIATE: {
      return {
        ...state, loader: true, blogData: {}
      }
    }
    case blogAction.BLOG_LIST_SUCCESS: {
      return {
        ...state, loader: false, blogData: payload.data
      }
    }
    case blogAction.BLOG_LIST_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    default: 
      return state
  }
}