import React, { useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import { Link } from "react-router-dom";

import {useDispatch, useSelector} from "react-redux"
import { fetchPlanHistory } from '../../redux/actions/subscriptionAction';
import moment from 'moment';


const SubscribePaymentHistory = () => {
    const dispatch = useDispatch()


    const { planHistory } = useSelector(
        (state) => state.subscriptionReducer
      );
    


    useEffect(()=>{

        dispatch(fetchPlanHistory())

    },[])





    // console.log(planHistory ,'PPPPPPPPPPPPP')






  return (
    <>
    <Header />
    <section class="current-plan">
        <div class="container">
            <div class="current-plan-area">
                <figcaption>
                    <h4>Subscription Payment History </h4>
                    <a href="#">Export to Excel</a>
                </figcaption>

                <div class="TableList">
                    <table style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Reference Number</th>
                                <th>Price</th>
                                <th></th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {planHistory?.map((item)=>
                            <tr>
                                <td>{moment(item?.subscriptionStartDate)?.format("DD-MM-YYYY")}</td>
                                <td>0215-15450-5956</td>
                                <td>₹ {item?.plans?.actualPrice}</td>
                                <td><Link to="/view-receipt">View Receipt</Link></td>
                            </tr>

)}

                                                 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <Footer />

    </>
  )
}

export default SubscribePaymentHistory