import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import Header from "../header";

import { forgot } from "../../redux/actions/authAction";

import Login from "../../assets/images/login.png";

const initialState = { password:'', password_empty:'', confirm_password:'', confirm_password_empty:'' }

const ChangePassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [password1Shown, setPassword1Shown] = useState(false);

    const toggle1PasswordVisiblity = () => {
      setPassword1Shown(password1Shown ? false : true);
    };
  
    const [password2Shown, setPassword2Shown] = useState(false);
    const [password3Shown, setPassword3Shown] = useState(false);
  
    const toggle2PasswordVisiblity = () => {
      setPassword2Shown(password2Shown ? false : true);
    };

    const [admin, setAdmin] = useState(initialState);
    const { password, password_empty, confirm_password, confirm_password_empty } = admin

    const getOTPRes = useSelector(state => state.client)
    const { otpForgotData } = getOTPRes

    const handleInput = (e) => {
        const {name, value} = e.target
        setAdmin({...admin, [name]:value})
    }

    const handleValidation = () => {
        let flag = true;
        let password_empty="";
        let confirm_password_empty="";

        if(!password) {
            flag = false
            password_empty = "Password field is required"
        } else {
            var flagStatus = passwordValidation(password);
            if(!flagStatus.flag) {
                flag = false
                password_empty = flagStatus.msg
            }
        }

        if(!confirm_password) {
            flag = false
            confirm_password_empty = "Confirm Password field is required"
        } else {
            if(password != confirm_password) {
                flag = false;
                confirm_password_empty = "Please make sure your password match.";
            }
        }

        setAdmin({...admin, password_empty, confirm_password_empty})

        return flag;
    }

    function passwordValidation(password) {
        // regular expressions to validate password
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var passwordValidation = "";
        var flag = true;

        if (!password.match(lowerCase)) {
            passwordValidation = "Password should contains lowercase letters!";
            flag = false;

        } else if (!password.match(upperCase)) {
            passwordValidation = "Password should contain uppercase letters!";
            flag = false;

        } else if (!password.match(numbers)) {
            passwordValidation = "Password should contains numbers also!";
            flag = false;

        } else if (password.length < 4) {
            passwordValidation = "Password length should be more than 4.";
            flag = false;
        }
        return {flag:flag, msg: passwordValidation};
    }

     const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()

        const checkValidation = handleValidation()
        if(checkValidation) {
            setButtonDisabled(true);

            let reqData = {email:otpForgotData.email, password:password}

            dispatch(forgot(reqData))
            .then((res) => {
                if(res.status == 200) {
                    toast.success("Password has been reset successfully")

                    setAdmin({ password:'', password_empty:'', confirm_password:'', confirm_password_empty:''})

                    setTimeout(() => {
                        navigate("/login")
                    }, 2000)
                } else {
                    setButtonDisabled(false);
                    toast.error(res.message)
                }
            })
            .catch((error) => {
                setButtonDisabled(false);
                // console.log(error)
            })
        }
    }
    
    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="login-page">
                    <div className="login-left">
                        <img src={Login} alt="Login" />
                    </div>
                    <div className="login-right">
                        <figcaption>
                            <h1>Change Password</h1>
                            <p>Please change your login password </p>
                        </figcaption>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>New Password</label>
                                <input                         type={password1Shown ? "text" : "password"}
 name="password" value={password} className="form-control" placeholder="Enter New Password" onChange={handleInput}/>
                                <span class="error">{password_empty}</span>
                                
                    <span class="Icon" onClick={toggle1PasswordVisiblity}>
                        {password1Shown ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </span>
                            </div>
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input                         type={password2Shown ? "text" : "password"}
 name="confirm_password" value={confirm_password} className="form-control" placeholder="Enter Confirm Password" onChange={handleInput} />
                                <span class="error">{confirm_password_empty}</span>
                                
                    <span class="Icon" onClick={toggle2PasswordVisiblity}>
                        {password2Shown ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </span>
                            </div>
            
                            <button type="submit" className="button" disabled={buttonDisabled}>Change Password</button>
                            <Link to="/login" className="button active">Skip</Link>

                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default ChangePassword