import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchcontent } from "../redux/actions/staticAction";

import Footer from "./footer";
import Header from "./header";

const Support = () => {
    const dispatch = useDispatch();

    const [content, setContent] = useState("");

    useEffect(() => {
        let reqData = {type:"HELP_SUPPORT"}
        dispatch(fetchcontent(reqData))
        .then((res) => {
            if(res.status == 200) {
                setContent(res.data.description)
            }
        }).catch()
    }, [])

    return (
        <>
            <section>
                <div className="about">
                    <div className="container">
                        {content}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Support