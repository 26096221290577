import { blogAction } from "../actionType";
import { url } from "../../config/config";
import {isLoggedIn} from "../../utils";
import axios from "axios";

export const categoryListInitiate = () => ({
    type: blogAction.CATEGORY_LIST_INITIATE
})
export const categoryListSuccess = (data) => ({
    type: blogAction.CATEGORY_LIST_SUCCESS,
    payload:data
})
export const categoryListFailure = (data) => ({
    type: blogAction.CATEGORY_LIST_FAILURE
})

export const popularTagsInitiate = () => ({
    type: blogAction.POPULAR_TAGS_INITIATE
})
export const popularTagsSuccess = (data) => ({
    type: blogAction.POPULAR_TAGS_SUCCESS,
    payload:data
})
export const popularTagsFailure = (data) => ({
    type: blogAction.POPULAR_TAGS_FAILURE
})

export const blogListInitiate = () => ({
    type: blogAction.BLOG_LIST_INITIATE
})
export const blogListSuccess = (data) => ({
    type: blogAction.BLOG_LIST_SUCCESS,
    payload:data
})
export const blogListFailure = (data) => ({
    type: blogAction.BLOG_LIST_FAILURE
})

export function fetchCategory(payload,type) {

    return dispatch => {
       dispatch(categoryListInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.get(`${url}web/category/list`)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(categoryListSuccess(data))
                    }
                    else{
                      dispatch(categoryListFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(categoryListFailure(err))
                    reject(err);
                })
        );
    }
}

export function popularTags(payload,type) {

    return dispatch => {
       dispatch(popularTagsInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.get(`${url}web/tag/list`)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(popularTagsSuccess(data))
                    }
                    else{
                      dispatch(popularTagsFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(popularTagsFailure(err))
                    reject(err);
                })
        );
    }
}

export function blogListing(payload,type) {

    return dispatch => {
       dispatch(blogListInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.get(`${url}web/blog/list?category=${payload.category ?payload.category:""}&tags=${payload.tags?payload.tags:''}&blog=${payload.blog?payload.blog:""}`)
                .then(response => {
                  const data = response.data
                    if (data.status && data.status == 200) {
                        dispatch(blogListSuccess(data))
                    }
                    else {
                      dispatch(blogListFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(blogListFailure(err))
                    reject(err);
                })
        ).then((res)=>res).catch();
    }
}