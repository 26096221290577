import React, { useEffect, useState, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";

import {
  fetchCategory,
  popularTags,
  blogListing,
} from "../../redux/actions/blogAction";
import { dateTimeFormat } from "../helpers/dateTimeFormat";

import Header from "../header";
import Footer from "../footer";

import MobileApp from "../../assets/images/mobile-app.png";
import Vector from "../../assets/images/Vector.png";
import Apple from "../../assets/images/apple.png";
import Android from "../../assets/images/android.png";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const initialState = {
  keywords: "",
  category: "",
  tags: "",
};

const BlogList = () => {
  const dispatch = useDispatch();
  const [searchState, setSearchSate] = useState("");

  const getBlogData = useSelector((state) => state.blogs);
  const { catListData, tagsData, blogData } = getBlogData;

  const [filters, setFilters] = useState(initialState);
  const { keywords, category, tags } = filters;

  const [categoryListing, setCategoryListing] = useState([]);
  const [pCount, updatePCount] = useState(1);
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const [readMore, updateReadMore] = useState(false);

  const fetchcategory = () => {
    dispatch(fetchCategory())
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  const fetchBlogs = () => {
    const reqData = { keywords, category, tags, page: activePage, limit: 10 };
    dispatch(blogListing(reqData))
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  const fetchTags = () => {
    dispatch(popularTags())
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  useEffect(() => {
    fetchcategory();
    fetchTags();
    fetchBlogs();
  }, []);

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);

    if (pageNumber > 1) {
      updatePCount((pageNumber - 1) * 10);
    } else {
      updatePCount(pageNumber * 1);
    }

    let reqData = { keywords, category, tags, page: pageNumber, limit: 10 };
    dispatch(fetchCategory(reqData))
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  const handleCategoryFilter = (item) => {
    setFilters({ ...filters, category: item });

    const reqData = { keywords, category: item, tags, page: 1, limit: 10 };
    dispatch(blogListing(reqData))
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  const handleTagsFilter = (item) => {
    setFilters({ ...filters, tags: item });

    const reqData = { keywords, category, tags: item, page: 1, limit: 10 };
    dispatch(blogListing(reqData))
      .then((res) => {
        if (res.status == 200) {
        } else if (res.status == 400) {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong! Please try after sometime.");
        }
      })
      .catch();
  };

  const handleSearch = (e) => {
    dispatch(
      blogListing({
        blog: e.target.value,
        keywords,
        category,
        tags,
        page: activePage,
      })
    );
    setSearchSate(e.target.value);
  };



  return (
    <>
      <Toaster
        position="top-right"
        gutter={8}
        containerClassName="mytoast"
        toastOptions={{
          error: {
            duration: 3000,
            style: { background: "#be1313", color: "#fff" },
          },
        }}
      />

      <section>
        <div className="blog-area">
          <div className="container">
            <div className="blog-header">
              <div className="row align-items-centre">
                <div className="col-6">
                  <h1>Blogs from our team</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam a volutpat lacus.
                  </p>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <input
                      type="search"
                      value={searchState}
                      onChange={handleSearch}
                      className="form-control"
                      placeholder="I’m looking for"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="blog">
              <div className="row">
                <div className="col-3">
                  <div className="blog-left">
                    <button className="Category-btn">Category</button>
                    <ul>
                      <li>
                        <a
                          className={category==""?"active":""}
                          onClick={() => {
                            handleCategoryFilter("")
                            setFilters({
                              category: "",
                              tags: "",
                              keywords: "",
                            });
                            setSearchSate("");
                          }}
                        >
                          All
                        </a>
                      </li>
                      {/* <li> */}
                      {catListData && catListData.length > 0
                        ? catListData.map((item) => (
                            <li>
                              {" "}
                              <a
                               className={category ==item.name?"active":""}
                                onClick={() => handleCategoryFilter(item.name)}
                              >
                                {item.name}{" "}
                              </a>
                            </li>
                          ))
                        : null}
                      {/* </li> */}
                    </ul>
                  </div>
                </div>

                <div className="col-9">
                  <div className="blog-right">
                    <h4>Popular Tags</h4>
                    <ul>
                      {tagsData && tagsData.length > 0
                        ? tagsData.map((item) => (
                            <li >
                              {" "}



                              <a onClick={() => handleTagsFilter(item?.tags)}>

                              {item.tags}
                              </a>
                           
                            </li>
                          ))
                        : null}
                    </ul>

                    <div className="row">
                      {blogData && blogData.result?.length > 0
                        ? blogData.result.map((item) => (
                            <div className="col-6">
                              <div className="blog-card">
                                <figure>
                                  <Link to="/blog-details" state={item}>
                                    <img
                                      src={item?.thumbnailImage}
                                      alt="blog"
                                    />
                                  </Link>
                                </figure>
                                <figcaption>
                                  <h4>
                                    <span> {item?.author} </span> •{" "}
                                    {dateTimeFormat(item?.createdAt)}{" "}
                                    {moment(item?.createdAt)?.format("YYYY")}{" "}
                                  </h4>
                                  <h3> {item?.title} </h3>

                                  <p>{item?.blog?.slice(0, 150)}</p>
                                  <Link to="/blog-details" state={item}>
                                    Read More
                                  </Link>
                                </figcaption>
                                <div className="blog-button">
                                  {item?.tags &&
                                    item?.tags?.map((ele) => (
                                      <button> {ele.tags} </button>
                                    ))}
                                </div>
                              </div>
                            </div>
                          ))
                        : <p style={{marginLeft:"300px", marginTop:"150px"}}>No data found</p>}
                    </div>

                    <div className="pagination">
                      {Object.keys(blogData).length > 0 &&
                      blogData.result.length > 0 ? (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={blogData?.total ?? 1}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemclassName="page-item"
                          linkclassName="page-link"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="mobile-app">
          <div className="container">
            <div className="mobile-app-right">
              <img src={MobileApp} alt="Mobile app" />
            </div>

            <div className="mobile-app-left">
              <span>
                <img src={Vector} alt="Vector" />
              </span>
              <h2>Download our mobile app</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />{" "}
                Nullam a volutpat lacus.
              </p>
              <div>
                <a href="#">
                  <img src={Apple} alt="apple" />
                </a>
                <a href="#">
                  <img src={Android} alt="android" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogList;
