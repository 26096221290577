import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import { isLoggedIn, yearDropdown } from "../../utils";
import { fetchCategory } from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchWordSearch, fetchcourtOrderCategoryDropDown } from "../../redux/actions/courtOrderAction";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const initialState = {
  category: "",

  startDate: "",

  endDate: "",

  keyWords: "",

  courtorderPdf: "",

  digestNotes: "",
  limit: "",
  page: "",
  countryName: [],
};

const UserWordSearch = () => {
  const [iState, updateState] = useState(initialState);
  const getProfileData = useSelector(state => state.profile)
  const {clientData} = getProfileData

  const {orderCategory} = useSelector((state) => state.courtOrderReducer);
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    category,

    startDate,

    endDate,

    keyWords,

    courtorderPdf,

    digestNotes,
    limit,
    page,
    countryName,
  } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const yearList = yearDropdown();

  const handleRadioCheck = (name) => {
    updateState({ ...iState, digestNotes: name });
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      let temp = countryName;
      temp.push(name);
      updateState({ ...iState, countryName: temp });
    } else {
      let temp = countryName;
      let i = countryName.indexOf(name);
      temp.splice(i, 1);

      updateState({ ...iState, countryName: temp });
    }
  };

  useEffect(() => {
    dispatch(fetchcourtOrderCategoryDropDown())
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if(Object.keys(clientData).length !== 0){
    if(isLoggedIn("clientLogin")){


    let checkValidation = true
    if (checkValidation) {
      let reqData = {
        category,

        startDate,
    
        endDate,
    
        keywords:keyWords
    
        // courtorderPdf,
    
        // digestNotes,
        // limit,
        // page,
        
          };
      dispatch(fetchWordSearch(reqData))
        .then((res) => {
          if (res.status == 200) {
            navigate("/user-search", {state:{result:res.data.result, digestNotes} });
            
          } else {
            toast.error("Something went wrong! Please try after sometime.");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }
  else{
    navigate('/login')
  }
  };
  return (
    <>
      <Toaster
        position="top-right"
        gutter={8}
        containerClassName="mytoast"
        toastOptions={{
          error: {
            duration: 3000,
            style: { background: "#be1313", color: "#fff" },
          },
        }}
      />

      <section>
        <div class="environmental">
          <div class="container">
            <div class="environmental-top">
              <h2>
                The Ultimate in <span>Environmental Legal Research </span>™
              </h2>
              <p>
                Utilize Advanced Search for precision or Search Tools to explore
                by Topic, Case, Citation, or section, etc.
              </p>
            </div>
            <div class="environmental-bottom">
              <aside>
                <h2>Find by Word Search</h2>
                <h4>
                  Search for Environmental Judgments in India, Bangladesh,
                  Pakistan, Sri Lanka, Nepal, Bhutan.”
                </h4>
                <p>
                  Enter keywords or phrases in the 'Search Text' box and click
                  'Find' to locate court orders or judgments related to specific
                  words or phrases in the listed countries. Results will display
                  court orders or judgements where your search query is found."
                </p>
              </aside>
              <article>
                <h3>Search Option</h3>
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label>Year From</label>
                        <select
                          onChange={handleChange}
                          value={startDate}
                          name="startDate"
                          class="form-control"
                        >
                          <option value={""}>--Select--</option>
                          {yearList?.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Year To</label>
                        <select
                          disabled={!startDate}
                          onChange={handleChange}
                          value={endDate}
                          name="endDate"
                          class="form-control"
                        >
                          <option value={""}>--Select--</option>
                          {yearList
                            ?.filter((item) => item > startDate)
                            ?.map((year) => (
                              <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Category</label>
                        <select value={category} name="category" onChange={handleChange} class="form-control">
                          <option value={""}>--Select--</option>
                          {orderCategory && orderCategory.length > 0
                            ? orderCategory.map((item) => (
                                <option value={item?.name}>{item?.name}</option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <h4>Select Area</h4>

                  <ul class="Avaialable Avaialable1">
                    <li>
                      <label class="Radio">
                        Entire Document
                        <input
                          type="radio"
                          onChange={() => handleRadioCheck("Entire Document")}
                          name="ac"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="Radio">
                        Digest Notes
                        <input
                          type="radio"
                          onChange={() => handleRadioCheck("Digest Notes")}
                          name="ac"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="Radio">
                        Both
                        <input
                          type="radio"
                          onChange={() => handleRadioCheck("Both")}
                          name="ac"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul> */}

                  <div class="file-info-checkbox">
                    <h4>Select Country</h4>
                    <div class="form-group1">
                      <label class="CheckBox">
                        India
                        <input
                          type="checkbox"
                          name="India"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="CheckBox">
                        Bangladesh
                        <input
                          type="checkbox"
                          name="Bangladesh"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="CheckBox">
                        Pakistan
                        <input
                          type="checkbox"
                          name="Pakistan"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="CheckBox">
                        Sri Lanka
                        <input
                          type="checkbox"
                          name="Sri Lanka"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="CheckBox">
                        Nepal
                        <input
                          type="checkbox"
                          name="Nepal"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="CheckBox">
                        Bhutan
                        <input
                          type="checkbox"
                          name="Bhutan"
                          onChange={handleCheckbox}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <h4>Enter Search Term</h4>
                  <div class="row align-items-centre">
                    <div class="col-9">
                      <div class="form-group">
                        <input
                          type="search"
                          name="keyWords"
                          onChange={handleChange}
                          value={keyWords}
                          class="form-control"
                          placeholder="Enter the word/terms to search court orders"
                        />
                      </div>
                    </div>
                    <div class="col-3">
                      <button  type="submit" class="Button">
                        Search{" "}
                        <span>
                          <img src="images/search.png" alt="" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <p>
                    <i>
                      Note: Use AND, NOT, OR and NEAR [all capitals] between
                      words for boolean search.
                    </i>
                  </p>
                </form>
              </article>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UserWordSearch;
