import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { profileLogout } from "../../redux/actions/profileAction";

import Dashboard from "../../assets/images/dashboard.png";
import Pass from "../../assets/images/pass.png";
import Logout from "../../assets/images/logout.png";

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const handleLogout = (e) => {
        e.preventDefault();
    
        dispatch(profileLogout())
        .then((res) => {
            if(res.status == 200) {
                window.location.href="http://3.13.196.242/legal_web/"
            } else {
                toast.success("Something Went Wrong!")
            }
        })
        .catch((err)=>{ 
            // console.log(err) 
            if(err.response) {
                toast.error(err.response.data.message)
            }
        })
    }

    return (
        <>
            <li><Link className={(splitLocation[1] === "profile" && splitLocation[2] === "") ? "active" : ""} to="/profile"><span><img src={Dashboard} alt="Dashboard" /></span> My Profile</Link></li>
            <li><Link className={splitLocation[2] === "password" ? "active" : ""} to="/profile/password"><span><img src={Pass} alt="Pass" /></span> Change Password</Link></li>
            <li><button type="button" onClick={handleLogout}><span><img src={Logout} alt="Logout" /></span> Log-out</button></li>
        </>
    );
}

export default Sidebar