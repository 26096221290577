import { profileAction } from "../actionType"
const initialState = {
  clientData: {},
  loader: false,
}

export default function profile(state = initialState, {type, payload}) {
  switch(type) {
    
    case profileAction.DETAIL_INITIATE: {
      return {
        ...state, loader: true, 
      }
    }
    case profileAction.DETAIL_SUCCESS: {
      return {
        ...state, loader: false, clientData: payload.data
      }
    }
    case profileAction.DETAIL_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    default: 
      return state
  }
}