import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import { Country, State, City } from 'country-state-city';
import S3FileUpload from 'react-s3';

import { profileDetail, profileUpdate } from "../../redux/actions/profileAction";

import Header from "../header";
import Footer from "../footer";
import Sidebar from "../profile/sidebar";

import Profile from "../../assets/images/account-placeholder.png";
import Loading from "../../assets/images/loading-img.gif";

const config = {
    bucketName: "auuction",
    dirName: "legal",
    region: "us-east-1",
    accessKeyId: "AKIAU4GHOSL2BYOEG7UV",
    secretAccessKey: "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",
};

const initialState = {
    image: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    address: '',
    postal_code: '',
    country: '',
    countryCode: "",
    state: '',
    stateCode: '',
    city: '',
    designation: '',
    organization: '',

    first_name_empty: '',
    last_name_empty: '',
    mobile_empty: '',
    address_empty: '',
    postal_code_empty: '',
    country_empty: '',
    state_empty: '',
    city_empty: '',
}

const MyAccount = () => {
    const dispatch = useDispatch();

    const [countryDropdown, setCountryDropdown] = useState(Country.getAllCountries());

    const [stateDropdown, setStateDropdown] = useState(State.getAllStates());
    const [stateDropdownList, setStateDropdownList] = useState([]);

    const [cityDropdown, setCityDropdown] = useState(City.getAllCities());
    const [cityDropdownList, setCityDropdownList] = useState([]);

    const [user, setUser] = useState(initialState);
    const {
        image,
        first_name,
        last_name,
        email,
        mobile,
        address,
        postal_code,
        country,
        countryCode,
        state,
        stateCode,
        city,
        designation,
        organization,

        first_name_empty,
        last_name_empty,
        mobile_empty,
        address_empty,
        postal_code_empty,
        country_empty,
        state_empty,
        city_empty,
    } = user

    const [preImage, setPreImage] = useState("");

    useEffect(() => {

        dispatch(profileDetail())
            .then((res) => {
                if (res.status == 200) {
                } else if (res.status == 400) {
                    toast.error(res.message)
                } else {
                    toast.error('Something went wrong! Please try after sometime.');
                }
            })
            .catch()
    }, [])

    const getProfileData = useSelector(state => state.profile)
    const { clientData } = getProfileData

    useEffect(() => {
        if (!_.isEmpty(clientData.result)) {

            let cData = clientData.result[0];

            const updateData = _.cloneDeep(user)
            updateData.first_name = cData.firstName
            updateData.last_name = cData.lastName
            updateData.email = cData.email
            updateData.mobile = cData.phoneNumber
            updateData.image = cData.image ? cData.image : Profile
            updateData.address = cData.billingAddress
            updateData.state = cData.state
            updateData.postal_code = cData.postalCode
            updateData.countryCode = cData.country.isoCode
            updateData.country = cData.country.name
            updateData.state = cData.state.name
            updateData.stateCode = cData.state.isoCode
            updateData.city = cData.city.name
            updateData.designation = cData.designation
            updateData.organization = cData.organization

            setPreImage(cData.image ? cData.image : Profile)

            setUser(updateData)
            setStateDropdownList(clientData.states)
            setCityDropdownList(clientData.cities)
        }
    }, [clientData])

    const handleInput = (e) => {
        const { name, value } = e.target


        if (name === "country") {

            let getCountry = countryDropdown.filter((item) => {
                return item.isoCode == value;
            })
            setUser({ ...user, [name]: getCountry[0]['name'], countryCode: getCountry[0]['isoCode'] });

            let stateResult = stateDropdown.filter((item) => {
                return item.countryCode == value;
            })
            setStateDropdownList(stateResult)

        } else if (name === "state") {

            let getState = stateDropdown.filter((item) => {
                return item.isoCode == value;
            })
            setUser({ ...user, [name]: getState[0]['name'], stateCode: getState[0]['isoCode'] });

            let cityResult = cityDropdown.filter((item) => {
                return item.stateCode == value;
            })
            setCityDropdownList(cityResult)

        } else if (name == "postal_code") {

            if (!/^[0-9]{0,6}$/.test(value) || e.target.value == "e") return;
            {
                setUser({ ...user, [name]: value });
            }
        }
        else {
            setUser({ ...user, [name]: value });
        }
    }

    const handleValidation = () => {
        let flag = true;
        let first_name_empty = "";
        let last_name_empty = "";
        let mobile_empty = "";
        let country_empty = "";
        let state_empty = "";
        let city_empty = "";
        let address_empty = "";

        if (!first_name) {
            flag = false;
            first_name_empty = "First name field is requried";
        } else {
            if (!/\S/.test(first_name)) {
                flag = false;
                first_name_empty = "First name field is requried";
            }
        }
        if (!last_name) {
            flag = false;
            last_name_empty = "Last name field is requried";
        } else {
            if (!/\S/.test(last_name)) {
                flag = false;
                last_name_empty = "Last name field is requried";
            }
        }

        if (!mobile) {
            flag = false;
            mobile_empty = "Mobile field is requried";
        }
        if (/^\d*$/.test(mobile)) { } else {
            flag = false;
            mobile_empty = "Please enter Numeric value";
        }

        if (!country) {
            flag = false;
            country_empty = "Country field is requried";
        }

        if (!state) {
            flag = false;
            state_empty = "State field is requried";
        }

        if (!city) {
            flag = false;
            city_empty = "City field is requried";
        }
        if (!address) {
            flag = false;
            address_empty = "Address field is requried";
        }
        setUser({
            ...user,
            first_name_empty: first_name_empty,
            last_name_empty: last_name_empty,
            mobile_empty: mobile_empty,
            country_empty: country_empty,
            state_empty: state_empty,
            city_empty: city_empty,
            address_empty: address_empty
        })
        return flag;
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let checkValidation = handleValidation();
        if (checkValidation) {

            let reqData = {
                firstName: first_name,
                lastName: last_name,
                email: email,
                phoneNumber: mobile,
                country: {
                    "name": country,
                    "isoCode": countryCode,
                },
                state: {
                    "name": state,
                    "isoCode": stateCode,
                    "countryCode": countryCode
                },
                city: {
                    "name": city,
                    "isoCode": stateCode
                },
                image: image,
                billingAddress: address,
                designation: designation,
                organization: organization,
                postalCode: postal_code
            }
            dispatch(profileUpdate(reqData))
                .then((res) => {
                    // console.log("res", res)
                    if (res.status == 200) {
                        toast.success('Profile updated successfully.');
                        dispatch(profileDetail())

                    } else {
                        toast.error('Something went wrong! Please try after sometime.');
                    }
                })
                .catch((err) => {
                    // console.log(err)
                })
        }
    }

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const handleS3Upload = (e) => {
        setUser({ ...user, image: Loading });

        const file = e.target.files[0];
        if (file) {

            // console.log(file)
            if (
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png"
            ) {
                S3FileUpload.uploadFile(file, config)
                    .then((data) => {
                        // console.log(data)
                        setUser({ ...user, image: data.location });
                        setPreImage(data.location);
                    })
                    .catch((err) => {
                        toast.error("Error");
                        // console.log(err);

                        setUser({ ...user, image: preImage });
                    })
            } else {
                toast.error("Please upload in JPEG,PNG,JPG format Only");
                setUser({ ...user, image: preImage });
            }
        }
    }




    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error: { duration: 3000, style: { background: '#be1313', color: '#fff' } } }} />
            <section>
                <div className="blog-area">
                    <div className="container">

                        <div className="blog">
                            <div className="row">
                                <div className="col-3">
                                    <div className="blog-left">
                                        <button className="Category-btn">My Account</button>
                                        <ul>
                                            <Sidebar />
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-9">
                                    <div className="my-account-left">
                                        <figure>
                                            <img src={image} alt="profile" />
                                        </figure>
                                        <figcaption>
                                            <div className="UploadBox">
                                                <div className="Upload">

                                                    <input type="file" onChange={handleS3Upload} /> Upload/Change
                                                </div>
                                            </div>
                                        </figcaption>
                                    </div>

                                    <div className="my-account-right">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" className="form-control" name="first_name" value={first_name} placeholder="Enter First Name" onChange={handleInput} />
                                                        <span className="error">{first_name_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control" name="last_name" value={last_name} placeholder="Enter Last Name" onChange={handleInput} />
                                                        <span className="error">{last_name_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Email ID</label>
                                                        <input type="text" className="form-control" name="email" value={email} placeholder="Enter Email ID" onChange={handleInput} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Mobile Number</label>
                                                        <input type="text" className="form-control" name="mobile" value={mobile} placeholder="Enter Mobile Number" onChange={handleInput} />
                                                        <span className="error">{mobile_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Billing Address</label>
                                                        <input type="text" className="form-control" name="address" value={address} placeholder="Enter Billing Address" onChange={handleInput} />
                                                        <span className="error">{address_empty}</span>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="postal_code" value={postal_code} placeholder="Enter Postal Code" onChange={handleInput} />
                                                        <span className="error">{postal_code_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <select className="form-control" name="country" value={countryCode} onChange={handleInput}>
                                                            <option value="">Country</option>
                                                            {
                                                                countryDropdown && countryDropdown?.filter((ele) => ele.name == "India" || ele.name == "Bangladesh" || ele.name == "Pakistan" || ele.name == "Bhutan" || ele.name == "Srilanka" || ele.name == "Nepal")?.map((item, index) => {
                                                                    return <option key={index} value={item.isoCode}> {item.name} </option>
                                                                })
                                                            }
                                                        </select>
                                                        <span className="error">{country_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <select className="form-control" name="state" value={stateCode} onChange={handleInput}>
                                                            <option value="">State/Province</option>
                                                            {
                                                                stateDropdownList && stateDropdownList.map((item, index) => {
                                                                    return <option key={index} value={item.isoCode}> {item.name} </option>
                                                                })
                                                            }
                                                        </select>
                                                        <span className="error">{state_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <select className="form-control" name="city" value={city} onChange={handleInput}>
                                                            <option value="">City</option>
                                                            {
                                                                cityDropdownList && cityDropdownList.map((item, index) => {
                                                                    return <option key={index} value={item.name}> {item.name} </option>
                                                                })
                                                            }
                                                        </select>
                                                        <span className="error">{city_empty}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Designation (optional)</label>
                                                        <input type="text" className="form-control" name="designation" value={designation} placeholder="Enter Designation (optional)" onChange={handleInput} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Organization (optional)</label>
                                                        <input type="text" className="form-control" name="organization" value={organization} placeholder="Enter Organization (optional)" onChange={handleInput} />
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="submit" className="Button">Save Details</button>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default MyAccount;