import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchcontent } from "../redux/actions/staticAction";

import Footer from "./footer";
import Header from "./header";

const Policy = () => {
    const dispatch = useDispatch();

    const [content, setContent] = useState("");

    useEffect(() => {
        let reqData = { type: "PRIVACY" }
        dispatch(fetchcontent(reqData))
            .then((res) => {
                if (res.status == 200) {
                    setContent(res.data.description)
                }
            }).catch()
    }, [])

    return (
        <>
            <section>
                <div className="about">
                    <h2 style={{textAlign:"center",fontWeight:"600",marginBottom:"25px"}}>Privacy Policy</h2>
                    <div className="container">
                        <p dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Policy