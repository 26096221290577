import React, { useDeferredValue, useState } from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {sentOTP} from "../../redux/actions/authAction";

import Header from "../header";
import Footer from "../footer";

import Login from "../../assets/images/login.png";
import SignFB from "../../assets/images/sign-fb.png";
import SignG from "../../assets/images/sign-g.png";

const initialState = {
    email:''
}

const Register = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [user, setUser] = useState(initialState)
    const { email } = user

    const handleInput = (e) => {
        const {name, value} = e.target
        setUser({...user, [name]:value})
    }

    const [isEnabled, setIsEnabled] = useState(false)
    const handleTerms = (e) => {
        if(isEnabled){
            setIsEnabled(false)
        } else {
            setIsEnabled(true)
        }
    }

    const handleValidation = () => {
        let flag = true;
        
        if(isEnabled == false) {
            flag = false;
            toast.error('Please accept the Terms and Condition');
            return false;
        }

        if (!email) {
            flag = false;
            toast.error('Email Field is required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            flag = false;
            toast.error('Invalid email address');
        }
        return flag;
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        let validation = handleValidation();
        if(validation) {
            const reqData = {email};
            dispatch(sentOTP(reqData))
            .then((res)=>{       

                if(res.status == 200) {

                    navigate("/signup/otp");
                } else {
                    toast.error('Something went wrong! Please try after sometime.');
                }
            })
            .catch((err)=> {            
                toast.error(err.response.data.message);
            })
        }
    }

    return (
        <>
            <Toaster position="top-right" gutter={8} containerClassName="mytoast" toastOptions={{ error:{duration: 3000, style:{background:'#be1313', color:'#fff'}}}} />
            <section>
                <div className="login-page">
                    <div className="login-left">
                        <img src={Login} alt="Login" />
                    </div>
                    <div className="login-right">
                        <figcaption>
                            <h1>Register as a free user</h1>
                            <p>Free to Read, Green Judgments (India, Bangladesh, Pakistan,Sri Lanka, Nepal, Bhutan). Download & Print - Subscribe Now!</p>
                        </figcaption>

                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input type="text" name="email" value={email} className="form-control" placeholder="Enter Email ID" onChange={(e)=>handleInput(e)}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="CheckBox">I Accept the <span>TERMS & CONDITIONS</span>
                                            <input type="checkbox" name="terms" onChange={(e)=>handleTerms(e)}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>                    
            
                            <button type="submit" className="button">Verify OTP</button>

                            <div className="register">
                                <p>Already a member? <Link to="/login"> Login</Link></p>
                            </div>
                            <div className="peragraph">
                            </div>

                            {/* <div className="sign-fb">
                                <a href="#"><img src={SignFB} alt="Sign facebook" /></a>
                                <a href="#"><img src={SignG} alt="Sign Google" /></a>
                            </div> */}
                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default Register