import React from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

import PrivateRoute from "./privateRoute";

import Home from "../src/components/home";
import About from "../src/components/about";
import BlogList from "../src/components/blog/blog";
import BlogDetail from "../src/components/blog/blog-detail";
import SubscribePlan from "./components/subscription/SubscribePlan";
import Contact from "../src/components/contact";

import Support from "../src/components/support";
import Terms from "../src/components/terms";
import Policy from "../src/components/policy";

import LoginAuth from "../src/components/auth/login";
import Register from "../src/components/auth/register";
import RegisterOTP from "../src/components/auth/register-otp";
import RegisterDetails from "../src/components/auth/register-detail";
import ForgotPassword from "../src/components/auth/forgot-password";
import ForgotPasswordOTP from "../src/components/auth/forgot-password-otp";
import ChangePassword from "../src/components/auth/change-password";

import MyAccount from "../src/components/profile/my-account";
import MyAccountPassword from "../src/components/profile/account-password";

import Congratulations from "./components/auth/congratulations";
import Error404 from "../src/components/error404";
import UserWordSearch from "./components/courtOrderSearch/user-word-search";
import UserSearch from "./components/courtOrderSearch/user-search";
import UserLawSearch from "./components/courtOrderSearch/user-law-search";
import UserCitationSearch from "./components/courtOrderSearch/user-citation-search";
import UserPartySearch from "./components/courtOrderSearch/user-party-search";
import UserCourtNameSearch from "./components/courtOrderSearch/user-courtname-search";
import UserCaseNumberSearch from "./components/courtOrderSearch/user-caseNumber-search";
import UserTopicSearch from "./components/courtOrderSearch/user-topic-search";
import SubscribeRenewPlan from "./components/subscription/SubscribeRenewPlan";
import ProceedToPay from "./components/subscription/ProceedToPay";
import SubscribePaymentHistory from "./components/subscription/SubscribePaymentHistory";
import ViewReceipt from "./components/subscription/ViewReceipt";
import Legislation from "./components/legislation";
import LegislationTabs from "./components/legislation-tabs";
import Header from "./components/header";



function App() {
  
const state = useLocation()


  return (
    <>
    {state.pathname !=="/df" &&<Header />}
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="blog" element={<BlogList />} />
            <Route path="blog-details" element={<BlogDetail />} />


            <Route path="subscribe-plan" element={<SubscribePlan />} />
            <Route path="contact" element={<Contact />} />

            <Route path="support" element={<Support />} />
            <Route path="terms" element={<Terms />} />
            <Route path="policy" element={<Policy />} />
            
            <Route path="login" element={<LoginAuth />} />
            <Route path="signup" element={<Register />} />
            <Route path="signup/otp" element={<RegisterOTP />} />
            <Route path="signup/details" element={<RegisterDetails />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="forgot-password/otp" element={<ForgotPasswordOTP />} />
            <Route path="forgot-password/reset" element={<ChangePassword />} />

            <Route path="congratulations" element={<Congratulations />} />

            <Route path="profile" element={<PrivateRoute component={MyAccount} />} />
            <Route path="profile/password" element={<PrivateRoute component={MyAccountPassword} />} />

            <Route path="user-word-search" element={<UserWordSearch />}  />
            <Route path="user-law-search" element={<UserLawSearch />}  />
            <Route path="user-citation-search" element={<UserCitationSearch />} />
            <Route path="user-party-search" element={<UserPartySearch />} />
            <Route path="user-courtName-search" element={<UserCourtNameSearch />} />
            <Route path="user-caseNumber-search" element={<UserCaseNumberSearch />} />
            <Route path="user-topic-search" element={<UserTopicSearch />} />
            <Route path="subscribe-renew-plan" element={<PrivateRoute component={SubscribeRenewPlan} />} />
            <Route path="proceed-to-pay" element={<PrivateRoute component={ProceedToPay} />} />
            <Route path="subscribe-payment-history" element={<PrivateRoute component={SubscribePaymentHistory} />} />
            <Route path="view-receipt" element={<PrivateRoute component={ViewReceipt} />} />
            <Route path="legislation" element={<Legislation/>} />
            <Route path="legislation-tabs" element={<PrivateRoute component={LegislationTabs} />} />






            <Route path="user-search" element={<PrivateRoute component={UserSearch} />} />



            <Route path="*" element={<Error404 />} />
        </Routes>
    </>
  );
}

export default App;
