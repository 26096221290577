import React, { useState, useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import MobileApp from "../assets/images/mobile-app.png";
import Vector from "../assets/images/Vector.png";
import Apple from "../assets/images/apple.png";
import Android from "../assets/images/android.png";
import Contact1 from "../assets/images/Contact-1.svg"
import Contact2 from "../assets/images/Contact-2.svg"

import { fetchcontent } from "../redux/actions/staticAction";
import { useDispatch } from "react-redux";

const Contact = () => {
    const [content, setContent] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        let reqData = { type: "CONTACT_US" }
        dispatch(fetchcontent(reqData))
            .then((res) => {
                if (res.status == 200) {
                    setContent(res.data)
                }
            }).catch()
    }, [])

    return (
        <>
            <section>
                <div className="mobile-app">


                    <div className="container">
                        <div className="row contant-card" >
                            <div class="col-sm-5">
                                <div class="ContactBox">
                                    <figure><img src={Contact1} /></figure>
                                    <h3>Talk to us</h3>
                                    <a href={`mailto:${content?.email}`}>Call Us</a>
                                    <h4>{content?.contact}</h4>
                                </div>

                            </div>
                            <div class="col-sm-5">
                                <div class="ContactBox">
                                    <figure><img src={Contact2} /></figure>
                                    <h3>Talk to us</h3>
                                    <a href={`mailto:${content?.email}`}>Email Us</a>
                                    <h4>{content?.email}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-app-right">
                            <img src={MobileApp} alt="mobile-app" />
                        </div>

                        <div className="mobile-app-left">
                            <span><img src={Vector} alt="Vector" /></span>
                            <h2>Download our mobile app</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Nullam a volutpat lacus.</p>
                            <div>
                                <a href="#"><img src={Apple} alt="apple" /></a>
                                <a href="#"><img src={Android} alt="android" /></a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default Contact;