import { combineReducers } from "redux";
import client from "../redux/reducers/authReducer";
import profile from "../redux/reducers/profileReducer";
import staticpage from "../redux/reducers/staticReducer";
import blogs from "../redux/reducers/blogReducer";
import subscriptionReducer from "../redux/reducers/subscriptionReducer";
import legislationReducer from "../redux/reducers/legislationReducer"
import courtOrderReducer from '../redux/reducers/courtOrderReducer'


const rootReducer = combineReducers({
    client,
    profile,
    staticpage,
    blogs,
    subscriptionReducer,
    legislationReducer,
    courtOrderReducer,

});
export default rootReducer