import React, { useEffect, useState } from "react";

import Header from "../header";
import Footer from "../footer";
import moment from "moment";
import { Link } from "react-router-dom";


import Tick from "../../assets/images/tick.png";
import Vector from "../../assets/images/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPlan,
  fetchCurrentPlan,
} from "../../redux/actions/subscriptionAction";

const SubscribePlan = () => {
  const [searchState, setSearchSate] =useState(false)

  const { currentPlan, subscriptionList } = useSelector(
    (state) => state.subscriptionReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPlan());
    dispatch(fetchCurrentPlan());
  }, []);

  return (
    <>
      <section className="current-plan">
        <div className="container">
          <div className="current-plan-area">
            <figcaption>
              <h4>My Current Plan</h4>
              <Link to="/subscribe-payment-history">
                Subscription Payment History
              </Link>
            </figcaption>

            <div className="current-plan-contant">
              <aside>
                <ul>
                  <li>
                    <p>Plan Name</p>
                    <h3 className="active">{ Array.isArray(currentPlan)? currentPlan?.[0]?.plans?.[0]?.name:currentPlan?.planName}</h3>
                  </li>
                  <li>
                    <p>Activated On</p>
                    <h3>
                      {Array.isArray(currentPlan)? moment(currentPlan?.[0]?.subscriptionStartDate).format("DD-MM-YYYY"): moment(currentPlan?.activatedOn).format("DD-MM-YYYY")}
                    </h3>
                  </li>
                  <li>
                    <p>Expiry Date</p>
                    <h3>
                      {Array.isArray(currentPlan)? moment(currentPlan?.[0]?.subscriptionExpiryDate).format("DD-MM-YYYY"): moment(currentPlan?.expiredOn).format("DD-MM-YYYY")}
                    </h3>
                  </li>
                  <li>
                    <p>Payment Type</p>
                    <h3>--</h3>
                  </li>
                  <li>
                    <Link to="/subscribe-renew-plan" state={subscriptionList?.[1]} className="Button">
                      Renew Plan
                    </Link>
                  </li>
                </ul>
              </aside>
              <article>
                <div>
                  <ul>
                    <li>
                      <p>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Lorem ipsum dolor sit
                      </p>
                      <p>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Lorem ipsum dolor sit
                      </p>
                      <p>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Lorem ipsum dolor sit
                      </p>
                    </li>
                    <li>
                      <h3>Price</h3>
                      <h2>
                        <b>₹{  Array.isArray(currentPlan)? currentPlan?.[0]?.plans?.[0]?.actualPrice :currentPlan?.actualPrice}</b>
                      </h2>
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="free-trails free-trails-1">
          <div className="container">
            <figcaption>
              <span>
                <img src={Vector} alt="Vector" />
              </span>
              <h3>
                Choose the right price plan <br /> for your needs
              </h3>
              <p>Choose from our affordable 3 packages</p>
            </figcaption>

            <div className="row">
              {subscriptionList?.map((plan) => (
                <div className="col-4">
                  <div className="your-plan">
                    <h4>{plan?.name}</h4>
                    <h2>
                      ₹{plan?.actualPrice} <span> / year</span>
                    </h2>
                    <p>Excluding GST</p>
                    <ul>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Indian Case Law
                      </li>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        Supreme Court of India
                      </li>
                      <li>
                        <span>
                          <img src={Tick} alt="tick" />
                        </span>{" "}
                        All High Courts
                      </li>
                    </ul>
                    <a className="button" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SubscribePlan;
