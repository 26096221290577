import React, { useState, useMemo, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Header from "../header";
import Footer from "../footer";

// import { Document, Page, pdfjs } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";
import { usePdfTextSearch } from "../usePdfTextSearch";
import { useDebounce } from "use-debounce";
import { useLocation } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UserSearch = () => {
  const { state } = useLocation();
  const defaultSearchString = " "; // just here for the sake of loading the page with default results
  const [searchString, setSearchString] = useState(defaultSearchString);
  const [debouncedSearchString] = useDebounce(searchString, 250);
  const [file, setFile] = useState(state?.result?.[0]?.courtorderPdf);

  return (
    <>
      <Toaster />

      <section>
        <div class="result-search">
          <div class="container">
            <div class="result-search-list">
              <div class="row">
                <div class="col-4">
                  <aside>
                    <h2>Result List</h2>
                    {/* 
                    <Document file={file}>
                    <ul>

                      {
                      
                      searchString &&
                        searchResults &&
                        searchResults.map((searchResultPageNumber) => (
                          <li>
                            <label>
                          <Page
                            key={searchResultPageNumber}
                            pageNumber={searchResultPageNumber}
                            customTextRenderer={textRenderer}
                          />
                          </label>
                          </li>
                        ))}
                                            </ul>



                    </Document> */}
                    <ul>
                      {state?.result?.map((courtOrder) => (
                        <>
                          {/* {(state?.digestNotes == "Entire Document" ||
                            state?.digestNotes == "Both") && ( */}
                            <li
                              onClick={() => setFile(courtOrder?.courtorderPdf)}
                            >
                              <figcaption>
                                <h5>{courtOrder?.courtName} (Court Order)</h5>
                                <p>Year :{courtOrder?.yearOfOrder}</p>
                              </figcaption>

                              <label>
                                <b>{courtOrder?.caseName} : </b>
                                {courtOrder?.category?.toString()}
                              </label>
                            </li>
                          {/* )} */}

                          {/* {(state?.digestNotes == "Digest Notes" ||
                            state?.digestNotes == "Both") && (
                            <li
                              onClick={() => setFile(courtOrder?.digestNotes)}
                            >
                              <figcaption>
                                <h5>{courtOrder?.courtName} (Digest Notes)</h5>
                                <p>year :{courtOrder?.yearOfOrder}</p>
                              </figcaption>

                              <label>
                                <b>{courtOrder?.caseName} :  </b>
                                {courtOrder?.category?.toString()}
                              </label>
                            </li>
                          )} */}
                        </>
                      ))}
                    </ul>
                  </aside>
                </div>
                <div class="col-8">
                  {/* <label>
                      <a href={file} target="_blank">
                        <span>
                          <img src="images/print.png" alt="" />
                        </span>{" "}
                        Print
                      </a>
                      <a href={file} target="_blank">
                        <span>
                          <img src="images/download.png" alt="" />
                        </span>{" "}
                        Download
                      </a>
                    </label> */}
                  {/* {file && <Document file={file}>
                        <ul>
                          {searchString &&
                            searchResults &&
                            searchResults.map((i) => (
                              <li>
                                <label>
                                  <Page
                                    key={i}
                                    pageNumber={i}
                                    customTextRenderer={""}
                                  />

                      
                                </label>
                              </li>
                            ))}
                        </ul>
                      </Document>} */}

                  {file && (
                    <iframe
                      class="pdf"
                      src={file}
                      width="800"
                      height="500"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UserSearch;

function highlightPattern(text, pattern) {
  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
        : [...arr, element],
    []
  );
}
