import { profileAction } from "../actionType";
import { url } from "../../config/config";
import {isLoggedIn} from "../../utils";
import axios from "axios";

export const detailInitiate = () => ({
    type: profileAction.DETAIL_INITIATE
})
export const detailSuccess = (data) => ({
    type: profileAction.DETAIL_SUCCESS,
    payload:data
})
export const detailFailure = (data) => ({
    type: profileAction.DETAIL_FAILURE
})

export const logoutInitiate = () => ({
    type: profileAction.LOGOUT_INITIATE
})
export const logoutSuccess = (data) => ({
    type: profileAction.LOGOUT_SUCCESS,
})
export const logoutFailure = (data) => ({
    type: profileAction.LOGOUT_FAILURE
})

export const passwordInitiate = () => ({
    type: profileAction.PASSWORD_INITIATE
})
export const passwordSuccess = (data) => ({
    type: profileAction.PASSWORD_SUCCESS,
})
export const passwordFailure = (data) => ({
    type: profileAction.PASSWORD_FAILURE
})

export function profileDetail(payload,type) {    
    const token = isLoggedIn('clientLogin')

    return dispatch => {
       dispatch(detailInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.get(`${url}web/list`, {
                headers: { Authorization: `${token}` }
            })
                .then(response => {
                  const data = response.data

                    if (data.status && data.status == 200) {
                        dispatch(detailSuccess(data))
                    }
                    else{
                      dispatch(detailFailure(data))
                    }
                    resolve(data);
                })
                .catch(err => {
                    dispatch(detailFailure(err.response.data))

                    reject(err.response.data);
                })
    ).then((res)=>res).catch();
    }
}

export function profileUpdate(payload,type) {    
    const token = isLoggedIn('clientLogin')

    return dispatch => {
       dispatch(detailInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/update`, payload, {
                headers: { Authorization: `${token}` }
            })
            .then(response => {
                const data = response.data
                if (data.status && data.status == 200) {
                    dispatch(detailSuccess(data))
                }
                else{
                    dispatch(detailFailure(data))
                }
                resolve(data);
            })
            .catch(err => {
                dispatch(detailFailure(err))
                reject(err);
            })
        );
    }
}

export function passwordUpdate(payload,type) {    
    const token = isLoggedIn('clientLogin')
    
    return dispatch => {
       dispatch(passwordInitiate(payload))
        return new Promise((resolve, reject) =>
            axios.post(`${url}web/update_password`, payload, {
                headers: { Authorization: `${token}` }
            })
            .then(response => {
                const data = response.data
                if (data.status && data.status == 200) {
                    dispatch(passwordSuccess(data))
                }
                else{
                    dispatch(passwordFailure(data))
                }
                resolve(data);
            })
            .catch(err => {
                dispatch(passwordFailure(err))
                reject(err);
            })
        );
    }
}

export function profileLogout(payload,type) {
    const token = isLoggedIn('clientLogin')
    return dispatch => {
       dispatch(logoutInitiate())
        return new Promise((resolve, reject) =>
            axios.get(`${url}user/logout`, {
                headers: { Authorization: `${token}` }
            })
            .then(response => {
                const data = response.data
                if (data.status && data.status == 200) {
                    window.localStorage.removeItem("clientLogin")
                    dispatch(logoutSuccess(data))
                }
                else{
                    dispatch(logoutFailure(data))
                }
                resolve(data);
            })
            .catch(err => {
                dispatch(logoutFailure(err))
                reject(err);
            })
        );
    }
}