import { staticAction } from "../actionType"

const initialState = {
  staticData: {},
  loader: false,
  loginStatus: false
}

export default function staticpage(state = initialState, {type, payload}) {
  switch(type) {
    
    case staticAction.CONTENT_INITIATE: {
      return {
        ...state, loader: true, staticData: {}
      }
    }
    case staticAction.CONTENT_SUCCESS: {
      return {
        ...state, loader: false, staticData: payload.data
      }
    }
    case staticAction.CONTENT_FAILURE: {
      return {
        ...state, loader: false
      }
    }

    default: 
      return state
  }
}