import React from "react";

import Header from "../header";
import Footer from "../footer";

import Login from "../../assets/images/login.png";
import Congrts from "../../assets/images/congrts.png";
import { Link } from "react-router-dom";

const Congratulations = () => {
    return (
        <>
            <section>
                <div class="login-page">
                    <div class="login-left">
                        <img src={Login} alt="Login" />
                    </div>
                    <div class="login-right">
                        <figure>
                            <img src={Congrts} alt="Congratulations" />
                        </figure>

                        <figcaption class="congrets">
                            <h1>Congratulations</h1>
                            <p>Your password has been changed successfully.</p>
                        </figcaption>

                        <form>     
                            <Link to="/login" class="button">Continue to Login</Link>
                        </form>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default Congratulations