import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchcontent } from "../redux/actions/staticAction";
import { dateTimeFormat } from "./helpers/dateTimeFormat";


import Header from "./header";
import Footer from "./footer";

import Vector from "../assets/images/Vector.png";
import ArrowRight from "../assets/images/arrow-right.png";
import Blog from "../assets/images/blog1.png";
import { Link } from "react-router-dom";

const About = () => {


  const dispatch = useDispatch();
  const [searchState, setSearchSate] = useState(false);
  const getBlogData = useSelector((state) => state.blogs);
  const { blogData } = getBlogData;
  const [content, setContent] = useState("");

  useEffect(() => {
    let reqData = { type: "PRIVACY" };
    dispatch(fetchcontent(reqData))
      .then((res) => {
        if (res.status == 200) {
          setContent(res.data.description);
        }
      })
      .catch();
  }, []);

  return (
    <>

      <section>
        <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-5">
                <div className="about-left">
                  <h2>
                    It's our world. These <br />
                    are our laws. This is <br />
                    our work. Join us.
                  </h2>
                </div>
              </div>
              <div className="col-7">
                <div className="about-right">
                  <span className="about-img">
                    <img src={Vector} alt="Vector" />
                  </span>
                  <h2>About SAREL</h2>

                  <p
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="editor"
                  ></p>

                  {/* <a href="read-more.html">Read More <img src={ArrowRight} alt="arrow-right" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section>
        <div className="blogs blogs-1">
          <figcaption>
            <span>
              <img src={Vector} alt="Vector" />
            </span>
            {/* <h3>
              Read our blogs to learn more <br /> about environmental law
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a{" "}
              <br /> volutpat lacus.
            </p> */}
          </figcaption>
          <div className="container">
            <div className="row">
              {blogData && blogData.result?.length > 0
                ? blogData.result.map((item) => (
                    <div className="col-6">
                      <div className="blog-card">
                        <figure>
                          <img src={item.image} alt="blog" />
                        </figure>
                        <figcaption>
                          <h4>
                            <span> {item.author} </span> •{" "}
                            {dateTimeFormat(item.createdAt)}{" "}
                          </h4>
                          <h3> {item.title} </h3>
                          <p>
                            {item?.blog?.slice(0, 150)}

                            <Link to="/blog-details" state={item}>
                              Read More
                            </Link>
                          </p>
                        </figcaption>
                        <div className="blog-button">
                          {item.tags ? (
                            <button> {item.tags.tags} </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
